.BatchBox {
  overflow: hidden;
}
.BatchBox .BatchBox_1 {
  line-height: 30px;
}
.BatchBox .BatchBox_2 {
  border: 1px solid #ebeef5;
  border-radius: 5px;
  height: 100px;
}
.BatchBox .BatchBoxFirst {
  width: 260px;
  background: #f2f7fd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.BatchBox .BatchBoxSecond {
  width: 100%;
}
.BatchBox .BatchBoxSecond .BatchforBox {
  display: flex;
  flex-wrap: wrap;
}
.BatchBox .BatchBoxSecond .BatchforBox .BatchforFlex {
  display: flex;
  align-items: center;
  padding: 5px 20px;
  cursor: pointer;
}
.BatchBox .BatchBoxSecond .BatchforBox .BatchforFlex .BatchImgBox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.BatchBox .BatchBoxSecond .BatchforBox .BatchforFlex img {
  width: 16px;
  height: 16px;
}
