





























































































































































































































.BatchBox {
  overflow: hidden;
  .BatchBox_1 {
    line-height: 30px;
  }
  .BatchBox_2 {
    border: 1px solid #ebeef5;
    border-radius: 5px;
    height: 100px;
  }
  .BatchBoxFirst {
    width: 260px;
    background: #f2f7fd;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .BatchBoxSecond {
    width: 100%;

    .BatchforBox {
      display: flex;
      flex-wrap: wrap;

      .BatchforFlex {
        display: flex;
        align-items: center;
        padding: 5px 20px;
        cursor: pointer;

        .BatchImgBox {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}